import * as React from "react";
import ReactDOM from "react-dom";
import * as api from "../api";
import { cleanPhone } from "../utils";
import { TrackConversion } from "./conversion";

interface Errors {
  phone?: "is-invalid";
  zipcode?: "is-invalid";
}

const initialState = {
  status: "form" as "form" | "loading" | "done",
  phone: "",
  zipcode: "",
  errors: {} as Errors,
};

type Action =
  | { type: "LOADING" }
  | { type: "FORM" }
  | { type: "DONE" }
  | { type: "ON_CHANGE"; id: string; value: string; checked: boolean }
  | { type: "SET_ERRORS"; errors: Errors };

type State = typeof initialState;

const useSmallForm = () => {
  const [state, dispatch] = React.useReducer((state: State, action: Action) => {
    switch (action.type) {
      case "FORM":
        return { ...state, status: "form" };
      case "LOADING":
        return { ...state, status: "loading" };
      case "DONE":
        return { ...state, status: "done" };
      case "ON_CHANGE":
        const { id, value } = action;
        switch (id) {
          case "phone":
            return {
              ...state,
              phone: cleanPhone(value),
              errors: { ...state.errors, phone: undefined },
            };
          case "zipcode":
            return {
              ...state,
              zipcode: value.toUpperCase().replace(/\D/g, "").substr(0, 5),
            };
          default:
            return state;
        }
      case "SET_ERRORS":
        const { errors } = action;
        return { ...state, errors };
    }
  }, initialState as State);
  const validate = (state: State) => {
    const errors = [
      state.phone.replace(/ /g, "").length != 10 ? "phone" : null,
      state.zipcode.length != 5 ? "zipcode" : null,
    ].filter(key => key !== null);
    return errors.length == 0 ? null : errors;
  };
  const change = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.id;
    dispatch({
      type: "ON_CHANGE",
      id,
      value: e.target.value,
      checked: e.target.checked,
    });
  };
  const setValue = (id: string, value: string) => {
    dispatch({
      type: "ON_CHANGE",
      id,
      value,
      checked: false,
    });
  };
  const submit = (e: React.FormEvent) => {
    e.preventDefault();
    if (state.status == "loading") {
      return;
    }
    const errors = validate(state);
    if (errors) {
      return dispatch({
        type: "SET_ERRORS",
        errors: errors.reduce(
          (acc, key) => ({ ...acc, [key]: "is-invalid" }),
          {}
        ),
      });
    }
    dispatch({ type: "LOADING" });
    api
      .post("/quick-contact", {
        phone: state.phone,
        zipcode: state.zipcode,
      })
      .then(() => dispatch({ type: "DONE" }))
      .catch(err => {
        console.log(err);
        dispatch({
          type: "FORM",
        });
      });
  };
  return { state, dispatch, change, setValue, submit };
};

export function SmallForm({
  visible = false,
  onClose = () => {},
}: {
  visible?: boolean;
  onClose?: () => void;
}): React.ReactElement {
  const { state, change, submit } = useSmallForm();
  const disabled = state.status != "form";
  return visible ? (
    ReactDOM.createPortal(
      <>
        {/* <div className="modal-overlay" onClick={onClose}></div> */}
        <div
          className="modal show fade d-block"
          style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
          onClick={() => onClose()}
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div
              className="modal-content"
              style={{ borderRadius: "25px" }}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <div className="modal-body p-4">
                <h2 className="h4 mb-3 text-danger text-center text-uppercase">
                  Trouvez l'assurance décennale proche de chez vous
                </h2>
                {["form", "loading"].includes(state.status) ? (
                  <form onSubmit={submit}>
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          className={"form-control " + state.errors.phone}
                          id="phone"
                          type="phone"
                          placeholder="Téléphone"
                          disabled={disabled}
                          onChange={change}
                          value={state.phone}
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          id="zipcode"
                          className={"form-control " + state.errors.zipcode}
                          type="text"
                          placeholder="Code postal"
                          disabled={disabled}
                          onChange={change}
                          value={state.zipcode}
                        />
                      </div>
                    </div>
                    <div className="text-center mt-3">
                      <button
                        type="submit"
                        className="btn-action"
                        disabled={disabled}
                      >
                        Valider
                      </button>
                    </div>
                  </form>
                ) : (
                  <>
                    <p className="lead">
                      Nous avons enregistré votre demande et vous rappellerons
                      très vite pour vous aider.
                    </p>
                    <TrackConversion />
                    <div className="text-center">
                      <a
                        onClick={e => {
                          e.preventDefault();
                          onClose();
                        }}
                        href="#"
                        className="btn-action"
                      >
                        Fermer
                      </a>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>,
      document.body
    )
  ) : (
    <></>
  );
}

export default SmallForm;
