import { GtagInit } from "./conversion";
import * as React from "react";
import Schema from "./schema";

export const Footer = () => {
  React.useEffect(() => {
    const to = setTimeout(() => {
      document.querySelectorAll(".embed-responsive-item").forEach(item => {
        if (!(item as any).initialized && item.getAttribute("data-src")) {
          item.setAttribute("src", item.getAttribute("data-src"));
          (item as any).initialized = true;
        }
      });
    }, 2000);
    () => clearTimeout(to);
  }, []);
  return (
    <>
      <footer className="container text-center pt-4">
        © {new Date().getFullYear()}
      </footer>
      <Schema />
      <GtagInit />
    </>
  );
};

export default Footer;
