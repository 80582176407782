import * as React from "react";
import { Map } from "../components/map";

export const Main = ({ children, withMap = false }) => {
  return (
    <main>
      {withMap ? (
        <div className="row">
          <div className="col-md-8">{children}</div>
          <div className="col-md-4">
            <Map />
          </div>
        </div>
      ) : (
        children
      )}
    </main>
  );
};
