import React from "react";

export default function Schema() {
  return (
    <div
      className="text-center mb-4"
      itemScope
      itemType="https://schema.org/InsuranceAgency"
    >
      <span itemProp="name">Votre Assurance Decennale</span>
      <div
        itemProp="aggregateRating"
        itemScope
        itemType="https://schema.org/AggregateRating"
      >
        <meta itemProp="ratingValue" content="4" />
        <meta itemProp="ratingCount" content="256" />
        <meta itemProp="bestRating" content="5" />
      </div>
      <a itemProp="url" href="https://www.votre-assurance-decennale.fr">
        www.votre-assurance-decennale.fr
      </a>
      <br />
      <a
        target="_blank"
        href="https://f1.np6.com/form.aspx?GV1=SASH0310000000000000000000000278"
      >
        Politique de confidentialité
      </a>{" "}
      -{" "}
      <a
        target="_blank"
        href="https://f1.np6.com/form.aspx?GV1=SASH031000000000000000000000027B"
      >
        Conditions générales
      </a>
    </div>
  );
}
