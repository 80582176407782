import SmallForm from "./small-form";
import * as React from "react";

export const Map = () => {
  const [modal, setModal] = React.useState(false);

  return (
    <div className="border-md-left pl-md-4 text-center">
      <h6 className="mt-4">Trouvez un spécialiste près de chez vous</h6>
      <a
        className="d-block my-2 mb-3"
        href="/devis"
        // onClick={e => {
        //   e.preventDefault();
        //   setModal(true);
        // }}
      >
        <img className="img-fluid" src="/map.png" />
      </a>
      <a
        href="/devis"
        className="btn-action"
        // onClick={e => {
        //   e.preventDefault();
        //   setModal(true);
        // }}
      >
        Obtenir mon devis
      </a>
      <SmallForm onClose={() => setModal(false)} visible={modal} />
    </div>
  );
};
